@tailwind base;
@tailwind components;
@tailwind utilities;

/* Colors: */
:root {
  --blue: #132577;
  --gray: #92989f;
  --gray-star: #7d7d7d;
  --yellow: #ffc107;
  --white: #ffffff;
  --black: #000000;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}
